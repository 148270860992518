import React from "react"
import { createContext, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

const GroundsDataContext = createContext()

const GroundsDataProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { id: { eq: "cG9zdDoxODQx" } }) {
        nodes {
          id
          title
          acfGrounds {
            description
            number
            title
            imageMobile {
              altText
              sourceUrl
            }
            backgroundImage {
              altText
              sourceUrl
            }
            additionalText1
            additionalText2
            additionalText3
            additionalText4
            additionalText5
            additionalText6
            description1
            description2
            description3
            description4
            description5
            description6
            title1
            title2
            title3
            title4
            title5
            title6
            subtitle6
            subtitle5
            subtitle4
            subtitle3
            subtitle2
            subtitle1
            subtitle
            number6
            number5
            number4
            number3
            number2
            number1
            imageMobile6 {
              altText
              sourceUrl
            }
            imageMobile5 {
              altText
              sourceUrl
            }
            imageMobile4 {
              altText
              sourceUrl
            }
            imageMobile3 {
              altText
              sourceUrl
            }
            imageMobile2 {
              altText
              sourceUrl
            }
            imageMobile1 {
              altText
              sourceUrl
            }
            icon1 {
              altText
              sourceUrl
            }
            icon2 {
              altText
              sourceUrl
            }
            icon3 {
              altText
              sourceUrl
            }
            icon4 {
              altText
              sourceUrl
            }
            icon5 {
              altText
              sourceUrl
            }
            icon6 {
              altText
              sourceUrl
            }
            backgroundImage1 {
              altText
              sourceUrl
            }
            backgroundImage2 {
              altText
              sourceUrl
            }
            backgroundImage3 {
              altText
              sourceUrl
            }
            backgroundImage4 {
              altText
              sourceUrl
            }
            backgroundImage5 {
              altText
              sourceUrl
            }
            backgroundImage6 {
              altText
              sourceUrl
            }
          }
          seo {
            title
            metaDesc
          }
        }
      }
    }
  `)

  return (
    <GroundsDataContext.Provider value={data}>
      {children}
    </GroundsDataContext.Provider>
  )
}

const useGroundsData = () => useContext(GroundsDataContext)

export { useGroundsData, GroundsDataContext }
export default GroundsDataProvider
