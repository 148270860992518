import "./style.scss"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, EffectFade } from "swiper"

import SlideStarterWrapper from "./components/SlideStarter"
import SlideOneWrapper from "./components/SlideOne"
import SlideTwoWrapper from "./components/SlideTwo"
import SlideThreeWrapper from "./components/SlideThree"
import SlideFourWrapper from "./components/SlideFour"
import SlideFiveWrapper from "./components/SlideFive"
import SlideSixWrapper from "./components/SlideSix"

const GroundsMobile = () => {
  SwiperCore.use([Navigation, Autoplay, EffectFade])

  const sliderSettings = {
    loop: false,
    slidesPerView: 1,
    autoplay: {
      delay: 10000,
    },
    centeredSlides: true,
    speed: 700,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 1,
    navigation: {
      nextEl: ".grounds-mobile-arrow--next",
      prevEl: ".grounds-mobile-arrow--prev",
    },
    autoHeight: true,
  }

  return (
    <div className="grounds-mobile-main d-lg-none">
      <Swiper {...sliderSettings}>
        <SwiperSlide>
          <SlideStarterWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideOneWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideTwoWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideThreeWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideFourWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideFiveWrapper />
        </SwiperSlide>
        <SwiperSlide>
          <SlideSixWrapper />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default GroundsMobile
