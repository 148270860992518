import "./styles.scss"

import React from "react"

import { useGroundsData } from "../../../../../context/grounds_data"
import GroundsDataProvider from "../../../../../context/grounds_data"

const SlideTwo = () => {
  const value = useGroundsData()

  const {
    additionalText2,
    description2,
    title2,
    number2,
    imageMobile2,
    icon2,
  } = value?.allWpPage?.nodes?.[0]?.acfGrounds

  return (
    <section className="grounds-mobile-two d-lg-none">
      <div className="grounds-mobile-two__hero">
        <div className="grounds-mobile-two__hero-img">
          <img src={imageMobile2?.sourceUrl} alt="" />
        </div>
        <div className="grounds-mobile-two__hero-content--wrapper">
          <div className="container">
            <div className="grounds-mobile-two__arrows">
              <button
                type="button"
                className="grounds-mobile-two__arrow grounds-mobile-two__arrow--prev grounds-mobile-arrow--prev"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
              <button
                type="button"
                className="grounds-mobile-two__arrow grounds-mobile-two__arrow--next grounds-mobile-arrow--next"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
            </div>
            <div className="grounds-mobile-two__content">
              <p className="grounds-mobile-two__number">{number2}</p>
              <div className="grounds-mobile-two__text">
                <p className="grounds-mobile-two__title">{title2}</p>
                <span className="grounds-mobile-two__divider"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grounds-mobile-two__bottom">
        <div className="container">
          <div className="grounds-mobile-two__bottom-first">
            <div className="grounds-mobile-two__icon">
              <img src={icon2?.sourceUrl} alt="" />
            </div>
            <div
              className="grounds-mobile-two__desc"
              dangerouslySetInnerHTML={{
                __html: description2,
              }}
            />
            <div className="grounds-mobile-two__cloud">
              <img src={require("assets/icons/cloud.svg").default} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="grounds-mobile-three__additional"
          dangerouslySetInnerHTML={{
            __html: additionalText2,
          }}
        />
      </div>
    </section>
  )
}

const SlideTwoWrapper = () => {
  return (
    <GroundsDataProvider>
      <SlideTwo />
    </GroundsDataProvider>
  )
}

export default SlideTwoWrapper
