import "./styles.scss"

import React from "react"

import { useGroundsData } from "../../../../../context/grounds_data"
import GroundsDataProvider from "../../../../../context/grounds_data"

const SlideSix = () => {
  const value = useGroundsData()

  const {
    additionalText6,
    description6,
    title6,
    number6,
    imageMobile6,
    icon6,
  } = value?.allWpPage?.nodes?.[0]?.acfGrounds

  return (
    <section className="grounds-mobile-five d-lg-none">
      <div className="grounds-mobile-five__hero">
        <div className="grounds-mobile-five__hero-img">
          <img src={imageMobile6?.sourceUrl} alt="" />
        </div>
        <div className="grounds-mobile-five__hero-content--wrapper">
          <div className="container">
            <div className="grounds-mobile-five__arrows">
              <button
                type="button"
                className="grounds-mobile-five__arrow grounds-mobile-five__arrow--prev grounds-mobile-arrow--prev"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
            </div>
            <div className="grounds-mobile-five__content">
              <p className="grounds-mobile-five__number d-none">{number6}</p>
              <div className="grounds-mobile-five__text">
                <p className="grounds-mobile-five__title">{title6}</p>
                <span className="grounds-mobile-five__divider"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grounds-mobile-five__bottom">
        <div className="container">
          <div className="grounds-mobile-five__bottom-first">
            <div className="grounds-mobile-five__icon">
              <img src={icon6?.sourceUrl} alt="" />
            </div>
            <div
              className="grounds-mobile-five__desc"
              dangerouslySetInnerHTML={{
                __html: description6,
              }}
            />
          </div>
        </div>
      </div>
      <div className="grounds-mobile-five__bottom--navy-blue">
        <div className="container">
          <div
            className="grounds-mobile-five__desc--navy-blue"
            dangerouslySetInnerHTML={{
              __html: additionalText6,
            }}
          />
        </div>
      </div>
    </section>
  )
}

const SlideSixWrapper = () => {
  return (
    <GroundsDataProvider>
      <SlideSix />
    </GroundsDataProvider>
  )
}

export default SlideSixWrapper
