import "./styles.scss"

import React from "react"

import { useGroundsData } from "../../../../../context/grounds_data"
import GroundsDataProvider from "../../../../../context/grounds_data"

const SlideOne = () => {
  const value = useGroundsData()

  const { description1, title1, number1, imageMobile1, icon1 } =
    value?.allWpPage?.nodes?.[0]?.acfGrounds

  return (
    <section className="grounds-mobile-one d-lg-none">
      <div className="grounds-mobile-one__hero">
        <div className="grounds-mobile-one__hero-img">
          <img src={imageMobile1?.sourceUrl} alt="" />
        </div>
        <div className="grounds-mobile-one__hero-content--wrapper">
          <div className="container">
            <div className="grounds-mobile-one__arrows">
              <button
                type="button"
                className="grounds-mobile-one__arrow grounds-mobile-one__arrow--prev grounds-mobile-arrow--prev"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
              <button
                type="button"
                className="grounds-mobile-one__arrow grounds-mobile-one__arrow--next grounds-mobile-arrow--next"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
            </div>
            <div className="grounds-mobile-one__content">
              <p className="grounds-mobile-one__number">{number1}</p>
              <div className="grounds-mobile-one__text">
                <p className="grounds-mobile-one__title">{title1}</p>
                <span className="grounds-mobile-one__divider"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grounds-mobile-one__bottom">
        <div className="container">
          <div className="grounds-mobile-one__bottom-first">
            <div className="grounds-mobile-one__icon">
              <img src={icon1?.sourceUrl} alt="" />
            </div>
            <div
              className="grounds-mobile-one__desc"
              dangerouslySetInnerHTML={{
                __html: description1,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const SlideOneWrapper = () => {
  return (
    <GroundsDataProvider>
      <SlideOne />
    </GroundsDataProvider>
  )
}

export default SlideOneWrapper
