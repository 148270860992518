import "./style.scss"

import React from "react"

import GroundsSlideOne from "./components/GroundsSlideOne/GroundsSlideOne"
import GroundsSlideTwo from "./components/GroundsSlideTwo/GroundsSlideTwo"
import GroundsSlideThree from "./components/GroundsSlideThree/GroundsSlideThree"
import GroundsSlideFour from "./components/GroundsSlideFour/GroundsSlideFour"
import GroundsSlideFive from "./components/GroundsSlideFive/GroundsSlideFive"
import GroundsSlideSix from "./components/GroundsSlideSix/GroundsSlideSix"

const GroundsDesktop = ({ data }) => {
  const background = data?.[0]?.acfGrounds?.backgroundImage?.sourceUrl

  return (
    <div className="grounds-desktop__wrapper d-none d-lg-block">
      <section className="grounds-desktop grounds-desktop-hero">
        <div
          className="grounds-desktop__hero"
          style={{
            backgroundImage: `url(${background})`,
          }}
        ></div>
        <div className="container">
          <div className="grounds-desktop__banner">
            <div className="grounds-desktop__banner-number">
              <img src={require("assets/icons/five.svg").default} alt="" />
            </div>
            <div className="grounds-desktop__banner-content">
              <p className="grounds-desktop__banner-title">
                {data?.[0]?.acfGrounds?.title}
              </p>
              <p className="grounds-desktop__banner-subtitle">
                {data?.[0]?.acfGrounds?.subtitle}
              </p>
              <span className="grounds-desktop__divider"></span>
            </div>
          </div>
          <div
            className="grounds-desktop__desc"
            dangerouslySetInnerHTML={{
              __html: data?.[0]?.acfGrounds?.description,
            }}
          />
        </div>
      </section>
      <GroundsSlideOne data={data?.[0]?.acfGrounds} />
      <GroundsSlideTwo data={data?.[0]?.acfGrounds} />
      <GroundsSlideThree data={data?.[0]?.acfGrounds} />
      <GroundsSlideFour data={data?.[0]?.acfGrounds} />
      <GroundsSlideFive data={data?.[0]?.acfGrounds} />
      <GroundsSlideSix data={data?.[0]?.acfGrounds} />
    </div>
  )
}

export default GroundsDesktop
