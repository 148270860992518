import "styles/pages/text-page.scss"

import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import GroundsDesktop from "../page_components/grounds/GroundsDesktop/GroundsDesktop"
import GroundsMobile from "../page_components/grounds/GroundsMobile"
import { useGroundsData } from "../context/grounds_data"
import GroundsDataProvider from "../context/grounds_data"

const Grounds = () => {
  const value = useGroundsData()

  const title = "5 Góðar Grundir"

  return (
    <Layout isHome>
      <Seo title={title} ogImage={withPrefix("/og-godar-grundir.jpg")} />
      <GroundsDesktop data={value?.allWpPage?.nodes} />
      <GroundsMobile />
    </Layout>
  )
}

const GroundWrapper = () => {
  return (
    <GroundsDataProvider>
      <Grounds />
    </GroundsDataProvider>
  )
}

export default GroundWrapper
