import "./styles.scss"

import React from "react"

import { useGroundsData } from "../../../../../context/grounds_data"
import GroundsDataProvider from "../../../../../context/grounds_data"

const SlideStarter = () => {
  const value = useGroundsData()

  const { imageMobile, description, title, subtitle } =
    value?.allWpPage?.nodes?.[0]?.acfGrounds

  return (
    <section className="grounds-mobile d-lg-none">
      <div className="grounds-mobile__hero">
        <div className="grounds-mobile__hero-img">
          <img src={imageMobile.sourceUrl} alt={imageMobile.altText} />
        </div>
        <div className="grounds-mobile__hero-content--wrapper">
          <div className="container">
            <div className="grounds-mobile__banner">
              <div className="grounds-mobile__banner--left">
                <img
                  className="grounds-mobile__banner-img"
                  src={require("assets/icons/five.svg").default}
                  alt=""
                />
                <div className="grounds-mobile__banner-content">
                  <p className="grounds-mobile__banner-title">{title}</p>
                  <p className="grounds-mobile__banner-subtitle">{subtitle}</p>
                </div>
              </div>
              <button
                type="button"
                className="grounds-mobile__slider-arrow grounds-mobile-arrow--next"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
            </div>
          </div>
          <div className="grounds-mobile__swipe">
            <p>
              Surfa og <br className="d-md-none" /> síggj meira
            </p>
            <img src={require("assets/icons/swipe.svg").default} alt="" />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="grounds-mobile__desc"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </section>
  )
}

const SlideStarterWrapper = () => {
  return (
    <GroundsDataProvider>
      <SlideStarter />
    </GroundsDataProvider>
  )
}

export default SlideStarterWrapper
