import "./styles.scss"

import React from "react"

import { useGroundsData } from "../../../../../context/grounds_data"
import GroundsDataProvider from "../../../../../context/grounds_data"

const SlideThree = () => {
  const value = useGroundsData()

  const {
    additionalText3,
    description3,
    title3,
    number3,
    imageMobile3,
    icon3,
  } = value?.allWpPage?.nodes?.[0]?.acfGrounds

  return (
    <section className="grounds-mobile-three d-lg-none">
      <div className="grounds-mobile-three__hero">
        <div className="grounds-mobile-three__hero-img">
          <img src={imageMobile3?.sourceUrl} alt="" />
        </div>
        <div className="grounds-mobile-three__hero-content--wrapper">
          <div className="container">
            <div className="grounds-mobile-three__arrows">
              <button
                type="button"
                className="grounds-mobile-three__arrow grounds-mobile-three__arrow--prev grounds-mobile-arrow--prev"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
              <button
                type="button"
                className="grounds-mobile-three__arrow grounds-mobile-three__arrow--next grounds-mobile-arrow--next"
              >
                <img
                  src={require("assets/icons/slider-arrow.svg").default}
                  alt=""
                />
              </button>
            </div>
            <div className="grounds-mobile-three__content">
              <p className="grounds-mobile-three__number">{number3}</p>
              <div className="grounds-mobile-three__text">
                <p className="grounds-mobile-three__title">{title3}</p>
                <span className="grounds-mobile-three__divider"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grounds-mobile-three__bottom">
        <div className="container">
          <div className="grounds-mobile-three__bottom-first">
            <div className="grounds-mobile-three__icon">
              <img src={icon3?.sourceUrl} alt="" />
            </div>
            <div
              className="grounds-mobile-three__desc"
              dangerouslySetInnerHTML={{
                __html: description3,
              }}
            />
          </div>
        </div>
      </div>
      <div className="grounds-mobile-three__bottom--navy-blue">
        <div className="container">
          <div
            className="grounds-mobile-three__desc--navy-blue"
            dangerouslySetInnerHTML={{
              __html: description3,
            }}
          />
        </div>
      </div>
      <div className="container">
        <div
          className="grounds-mobile-three__additional"
          dangerouslySetInnerHTML={{
            __html: additionalText3,
          }}
        />
      </div>
    </section>
  )
}

const SlideThreeWrapper = () => {
  return (
    <GroundsDataProvider>
      <SlideThree />
    </GroundsDataProvider>
  )
}

export default SlideThreeWrapper
