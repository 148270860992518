import "./style.scss"

import React from "react"

const GroundsSlideFour = ({ data }) => {
  const { title4, description4, icon4, additionalText4, number4 } = data
  const background = data?.backgroundImage4?.sourceUrl
  const icon = icon4?.sourceUrl

  return (
    <section className="grounds-desktop grounds-desktop-four">
      <div
        className="grounds-desktop__hero"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="container">
        <div className="grounds-desktop__slide">
          <div className="grounds-desktop__slide-number">{number4}</div>
          <div className="grounds-desktop__banner-content">
            <p className="grounds-desktop__banner-title">{title4}</p>
            <span className="grounds-desktop__divider"></span>
          </div>
        </div>
        <div
          className="grounds-desktop__slide-desc"
          dangerouslySetInnerHTML={{
            __html: description4,
          }}
        />
        <div className="grounds-desktop__bottom grounds-desktop__bottom--center">
          <div className="grounds-desktop__bottom-icon">
            <img src={icon} alt="" />
          </div>
          <div
            className="grounds-desktop__bottom-text"
            dangerouslySetInnerHTML={{
              __html: additionalText4,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default GroundsSlideFour
