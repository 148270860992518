import "./style.scss"

import React from "react"

const GroundsSlideOne = ({ data }) => {
  const { title1, description1, icon1, additionalText1, number1 } = data
  const background = data?.backgroundImage1?.sourceUrl
  const icon = icon1?.sourceUrl

  return (
    <section className="grounds-desktop grounds-desktop-one">
      <div
        className="grounds-desktop__hero"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="container">
        <div className="grounds-desktop__slide">
          <div className="grounds-desktop__slide-number">{number1}</div>
          <div className="grounds-desktop__banner-content">
            <p className="grounds-desktop__banner-title">{title1}</p>
            <span className="grounds-desktop__divider"></span>
          </div>
        </div>
        <div
          className="grounds-desktop__slide-desc"
          dangerouslySetInnerHTML={{
            __html: description1,
          }}
        />
        <div className="grounds-desktop__bottom grounds-desktop__bottom--center">
          <div className="grounds-desktop__bottom-icon">
            <img src={icon} alt="" />
          </div>
          <div
            className="grounds-desktop__bottom-text"
            dangerouslySetInnerHTML={{
              __html: additionalText1,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default GroundsSlideOne
