import "./style.scss"

import React from "react"

const GroundsSlideSix = ({ data }) => {
  const { title6, description6, icon6, additionalText6, number6 } = data
  const background = data?.backgroundImage6?.sourceUrl
  const icon = icon6?.sourceUrl

  return (
    <section className="grounds-desktop grounds-desktop-six">
      <div
        className="grounds-desktop__hero"
        style={{
          backgroundImage: `url(${background})`,
        }}
      ></div>
      <div className="container">
        <div className="grounds-desktop__slide">
          <div className="grounds-desktop__slide-number">{number6}</div>
          <div className="grounds-desktop__banner-content">
            <p className="grounds-desktop__banner-title">{title6}</p>
            <span className="grounds-desktop__divider"></span>
          </div>
        </div>
        <div
          className="grounds-desktop__slide-desc"
          dangerouslySetInnerHTML={{
            __html: description6,
          }}
        />
        <div className="grounds-desktop__bottom">
          <div className="grounds-desktop__bottom-icon">
            <img src={icon} alt="" />
          </div>
          <div
            className="grounds-desktop__bottom-text"
            dangerouslySetInnerHTML={{
              __html: additionalText6,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default GroundsSlideSix
